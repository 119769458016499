;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
};p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,'script','https://huntresscdn.com/19680a27e88da4a3713af26571b4849096e75d617f2845574af7fd15746256bb.js','snowplow'));
window.snowplow('newTracker', 'cf', 'webhooks.fivetran.com/snowplow/3e5147cc-2e3b-4022-a855-2f591cc7762f', { // Initialize a tracker
  appId: 'huntress-io' ,
  cookieDomain: 'huntress.io',
  cookieSameSite: 'Lax',
  post: true,
  forceSecureTracker: true,
  contexts: {
    webPage: true,
    gaCookies: false
  }
});
window.snowplow('enableActivityTracking', 10, 10);
window.snowplow('enableFormTracking');
window.snowplow('enableLinkClickTracking', null, true);
window.snowplow('setUserId', document.querySelector('meta[name="user_id"]')?.content);
window.snowplow('trackPageView');

// Check for dynamically added forms and links
const observer = new MutationObserver(function(records) {
  for (const record of records) {
    if (record.type === "childList" && record.addedNodes.length > 0) {
      for (const node of record.addedNodes) {
        if (node.nodeName === "FORM") {
          window.snowplow('enableFormTracking');
        }

        if (node.nodeName === "A") {
          window.snowplow('refreshLinkClickTracking');
        }
      }
    }
  }
})
observer.observe(document, { childList: true, subtree: true })