import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let options = this.element.dataset.options ? JSON.parse(this.element.dataset.options) : {}
    let defaultOptions = {
      placeholder: "Select an option...",
      allowClear: true,
      theme: "bootstrap",
      width: "100%",
      templateResult: this.formatState,
      templateSelection: this.formatState,
    }
    if (this.parentModal) {
      defaultOptions.dropdownParent = this.parentModal
    }
    options = {
      ...defaultOptions,
      ...options
    }
    $(this.element).select2(options)

    // https://makandracards.com/makandra/71890-events-triggered-by-jquery-cannot-be-observed-by-native-event-listeners
    // https://psmy.medium.com/rails-6-stimulus-and-select2-de4a4d2b59e4
    // https://select2.org/programmatic-control/events
    $(this.element).on("select2:close", function () {
      let event = new Event("select2:close:asNativeEvent", { bubbles: true })
      this.dispatchEvent(event)
    })
  }

  formatState(state) {
    if (!state.id) {
      return state.text
    }

    const $state = $("<span>")
    $state.text(state.text)
    const attributes = $(state.element).prop("attributes")

    $.each(attributes, function() {
      if (this.name.startsWith("data-")) {
        $state.attr(this.name, this.value)
      }
    })

    return $state
  }

  get parentModal() {
    return this.element.closest(".modal")
  }
}
