import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.setupSingleToggleCallbacks()
  }

  setupSingleToggleCallbacks() {
    if (this.hasContainerTarget) {
      $(this.containerTarget).on("click", ".bulk-select", this.handleToggleSingle.bind(this))
    }
  }

  handleToggleSingle(evt) {
    evt.target.checked === true ? this.enableBulkActions() : this.maybeDisableBulkActions()
  }

  handleToggleAll(evt) {
    let checked = evt.target.checked
    this.coerceAllSelections(checked)
    checked === true ? this.maybeEnableBulkActions() : this.disableBulkActions()
  }

  coerceAllSelections(checked) {
    $(".bulk-select:not(:disabled)", this.rootElement)
      .prop("checked", checked)
      .each((idx, element) => element.dispatchEvent(new Event("change")))
  }

  enableBulkActions() {
    $(".bulk-buttons button", this.rootElement).removeClass("disabled").attr("disabled", false)
  }

  disableBulkActions() {
    $(".bulk-buttons button", this.rootElement).addClass("disabled").attr("disabled", true)
  }

  maybeEnableBulkActions() {
    if ($(".bulk-select:not(:disabled):checked", this.rootElement).length > 0) {
      this.enableBulkActions()
    }
  }

  maybeDisableBulkActions() {
    if ($(".bulk-select:not(:disabled):checked", this.rootElement).length === 0) {
      this.disableBulkActions()
    }
  }

  bulkCategorize() {
    $("#bulk_update_ids", this.rootElement).val(this.getSelectedIds())
    $("#bulk-categorize-modal").modal()
  }

  bulkTask() {
    const agent_ids = this.getSelectedIds()
    let attr
    if (document.getElementById("bulk-task-button").dataset.attribute) {
      attr = document.getElementById("bulk-task-button").dataset.attribute
    } else {
      attr = "agent_ids"
    }
    const href = document.getElementById("bulk-task-button").dataset.url + "?" + attr + "=" + agent_ids
    window.location.href = href
  }

  bulkMove() {
    $("#bulk_update_agent_ids", this.rootElement).val(this.getSelectedIds())
    $("#bulk-move-modal").modal()
  }

  bulkUninstall() {
    $("#bulk_uninstall_agent_ids", this.rootElement).val(this.getSelectedIds())
    $("#bulk-uninstall-modal").modal()
  }

  getSelectedIds() {
    const selections = this.rootElement.querySelectorAll(".bulk-select:checked")
    return [...selections].map(selection => selection.dataset.id).join(", ")
  }

  getSelectedIdsCount() {
    const selections = this.rootElement.querySelectorAll(".bulk-select:checked")
    return selections.length
  }

  getSelectedRows() {
    const selections = this.rootElement.querySelectorAll(".bulk-select:checked")
    return [...selections].map(selection => selection.closest("tr"))
  }

  get rootElement() {
    // bulk actions controller can be scoped to only search within a certain element
    return this.element.dataset.rootNode ? document.querySelector(this.element.dataset.rootNode) : document
  }
}
