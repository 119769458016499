import { Controller } from "@hotwired/stimulus"

const ClipboardJS = require("clipboard")

export default class extends Controller {
  connect() {
    this.clipboard = new ClipboardJS(this.element)
      .on("success", (event) => {
        this.showTooltip("Copied!")
      })
      .on("error", (event) => {
        this.showTooltip("Failed!")
      })

    this.element.dataset.placement = "bottom"
    this.element.dataset.trigger = "manual"
  }

  showTooltip(message) {
    $(this.element).attr("data-original-title", message).tooltip("show")
    setTimeout(() => { $(this.element).tooltip("hide") }, 1000)
  }
}
