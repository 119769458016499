import { Controller } from "@hotwired/stimulus"
import "leaflet/dist/leaflet.css"
import "leaflet.markercluster/dist/MarkerCluster.css"
import L from "leaflet"
import "leaflet.markercluster/dist/leaflet.markercluster.js"

export default class extends Controller {
  static targets = ["welcomeModal", "welcomeModalDismiss"]

  connect() {
    this.setUpMap()
    this.firstTimeModal()
  }

  setUpMap(){
    let map = L.map("map").setView([30, 0], 2)
    L.tileLayer("https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}", {
      minZoom: 1,
      maxZoom: 20,
      attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      ext: "png"
    }).addTo(map)
    
    let markers = L.markerClusterGroup({
      singleMarkerMode: true
    })

    let mapLocations = $("#map").data("map-locations")
    for(let i = 0; i < mapLocations.length; i++){
      let location = mapLocations[i]
      for(let j = 0; j < location[2]; j++){
        let marker = L.marker(L.latLng(location[0], location[1]))
        markers.addLayer(marker)
      }
    }
    
    map.addLayer(markers)
  }

  firstTimeModal(){
    let dismissed = localStorage.getItem("unwantedAccessModalDismissed")

    if (dismissed)
      return

    $(this.welcomeModalDismissTarget).on("click", ()=>{
      localStorage.setItem("unwantedAccessModalDismissed", "true")
    })

    $(this.welcomeModalTarget).modal("show")
  }
}
