import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    let select =  $("#managed_identity_task_data_task_type")
    select.on("change", (event) => {
      let val = select.val()

      if (val == "ManagedIdentity::Tasks::DeleteInboxRule"){
        this.showRuleIdField()
      }
      else{
        this.hideRuleIdField()
        this.clearRuleIdField()
      }
    })
  }

  hideRuleIdField(){
    $(".ruleIdField").fadeOut()
  }

  clearRuleIdField(){
    $(".ruleIdField").find("input").val("")
  }

  showRuleIdField(){
    $(".ruleIdField").fadeIn()
  }
}
