import { Controller } from "@hotwired/stimulus"
import { parseNestedFormData, flashHighlightId } from "huntressHelpers"

export default class extends Controller {
  static targets = [
    "form",
    "messages",
    "overrideSubmitForm",
    "submitForm",
    "usageLocationMessage",
  ]

  static outlets = [ "accounts--unwanted-access--rules--expiration-date" ]

  connect() {
    if(this.hasSubmitFormTarget){
      $(this.submitFormTarget).on("click", () => {
        this.submitForm()
      })
    }
    if(this.hasOverrideSubmitFormTarget){
      $(this.overrideSubmitFormTarget).on("click", () => {
        this.submitForm(true)
      })
    }
  }

  parseFormData(){
    const formData = new FormData($("#attribute-rule-form").get(0))
    const formObject = parseNestedFormData(formData)
    return formObject
  }

  async submitForm(override=false){
    // FIXME: Remove the `this.has...Outlet &&` part of this `if` condition
    // when the `itdr_attribute_rule_expires_at` feature flag has been removed
    if (this.hasAccountsUnwantedAccessRulesExpirationDateOutlet &&
        !this.accountsUnwantedAccessRulesExpirationDateOutlet.hasValidValue()) {
      return this.displayMessage("error", 'Set an expiration date, or select "Never Expires".')
    }

    let formObject = this.parseFormData()
    if(override){
      formObject["overridden"] = true
    }
    const response = await fetch($("#attribute-rule-form").get(0).action, {
      method: formObject?._method?.toUpperCase() || "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formObject),
    })

    if(response.status != 200){
      try {
        const jsonResponse = await response.json()
        return this.displayMessage("error", jsonResponse.error)
      }
      catch (_) {
        // Reload if unable to parse error, there should be a flash message
        window.location.reload()
      }
    }

    const jsonResponse = await response.json()
    if(jsonResponse.identity_count){
      const count = jsonResponse.identity_count
      const country = jsonResponse.country
      return this.showIdentityCountModal(country, count)
    }
    window.location.hash = jsonResponse.redirect_anchor
    window.location.reload()
  }

  determinationExpected(){
    // FIXME: Remove the wrapping `if` condition
    // when the `itdr_attribute_rule_expires_at` feature flag has been removed
    if (this.hasAccountsUnwantedAccessRulesExpirationDateOutlet) {
      this.accountsUnwantedAccessRulesExpirationDateOutlet.enableFields()
    }
    this.usageLocationMessageTarget.classList.add("hidden")
  }

  determinationUnauthorized(){
    // FIXME: Remove the wrapping `if` condition
    // when the `itdr_attribute_rule_expires_at` feature flag has been removed
    if (this.hasAccountsUnwantedAccessRulesExpirationDateOutlet) {
      this.accountsUnwantedAccessRulesExpirationDateOutlet.disableFields()
    }
    this.usageLocationMessageTarget.classList.remove("hidden")
  }

  showIdentityCountModal(country, count){
    $("#title-country").text(country)
    $("#country").text(country)
    $("#identity-count").text(count)

    $(".ajax-modal").modal("hide")
    $("#rule-confirmation-modal").modal("show")
  }

  displayMessage(level, message){
    const messagesEl = $(this.messagesTarget)
    const messageEl = messagesEl.find(".message")
    let color = ""
    messagesEl.find(".fa").each((_, e) => {
      $(e).hide()
    })
    switch (level) {
    case "success":
      color = "#18BC9C"
      messagesEl.find(".success-icon").show()
      break
    case "warning":
      color = "#3498DB"
      messagesEl.find(".warning-icon").show()
      break
    case "error":
      color = "#E74C3C"
      messagesEl.find(".error-icon").show()
      break
    }

    messageEl.text(message)
    messageEl.css("color", color)
    messagesEl.show()
    flashHighlightId(messagesEl, 500, color)
  }
}
