import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"
import { flashMessage } from "huntressHelpers"

export default class extends Controller {
  static targets = [
    "hostIsolationToggle",
    "identityIsolationToggle",
    "autoRemediationsToggle",
    "credentialReportsToggle",
    "hostIsolationExceptionsToggle",
    "hostIsolationConfirmationModal",
    "identityIsolationConfirmationModal",
    "autoRemediationsSeveritiesContainer",
    "severityToggle",
  ]

  hostIsolationStatus = false
  identityIsolationStatus = false
  severityCallbacks = 0

  connect() {
    if (this.hasHostIsolationToggleTarget) {
      this.hostIsolationStatus = this.hostIsolationToggleTarget.checked
    }

    if (this.hasIdentityIsolationToggleTarget) {
      this.identityIsolationStatus = this.identityIsolationToggleTarget.checked
    }

    if (this.hasHostIsolationToggleTarget) {
      $(this.hostIsolationToggleTarget).on("change", (e) => {
        if (!e.currentTarget.checked) {
          $(this.hostIsolationConfirmationModalTarget).modal("show")
          this.hostIsolationStatus = true
        } else if (!this.hostIsolationStatus && e.currentTarget.checked) {
          this.ajaxToggleHostIsolation(true)
        }
      })
    }

    if (this.hasIdentityIsolationToggleTarget) {
      $(this.identityIsolationToggleTarget).on("change", (e) => {
        if (!e.currentTarget.checked) {
          $(this.identityIsolationConfirmationModalTarget).modal("show")
          this.identityIsolationStatus = true
        } else if (!this.identityIsolationStatus && e.currentTarget.checked) {
          this.ajaxToggleIdentityIsolation(true)
        }
      })
    }

    if (this.hasAutoRemediationsToggleTarget) {
      this.setAutoRemediationsSeveritiesState(null)

      $(this.autoRemediationsToggleTarget).on("change", (e) => {
        this.ajaxToggleAutoRemediations()
        this.setAutoRemediationsSeveritiesState(e)
      })
    }

    if (this.hasCredentialReportsToggleTarget) {
      $(this.credentialReportsToggleTarget).on("change", (e) => {
        this.ajaxTogglecredentialReports()
      })
    }

    if (this.hasHostIsolationExceptionsToggleTarget) {
      $(this.hostIsolationExceptionsToggleTarget).on("change", (e) => {
        this.ajaxToggleHostIsolationExceptions()
      })
    }

    if (this.hasSeverityToggleTarget) {
      $(this.severityToggleTargets).on("change", (e) => {
        let target = $(e.target)
        let state = target.prop("checked")
        this.ajaxToggleAutoRemediationsSeverity(target.data("severity"), state)
      })
    }
  }

  setAutoRemediationsSeveritiesState(event){
    let feature_enabled = $(this.autoRemediationsToggleTarget).prop("checked")
    if(feature_enabled){
      $(this.severityToggleTargets).bootstrapToggle("enable")
      $(this.severityToggleTargets).each((i , e) => {
        $(e).parents().eq(0).find(".btn").removeClass("cursor-not-allowed")
      })
      $(".severity-text").removeClass("disabled-text")
      if (event) {
        this.severityCallbacks = 0
        $(this.severityToggleTargets).each((i , e) => {
          let target = $(e)
          if(target.prop("checked") == false){
            target.parents().eq(0).trigger("click")
            this.severityCallbacks++
          }
        })
      }
    } else {
      if (event) {
        this.severityCallbacks = 0
        $(this.severityToggleTargets).each((i , e) => {
          let target = $(e)
          if(target.prop("checked") == true){
            target.parents().eq(0).trigger("click")
            this.severityCallbacks++
          }
        })
      }
      $(this.severityToggleTargets).bootstrapToggle("disable")
      $(this.severityToggleTargets).each((i , e) => {
        $(e).parents().eq(0).find(".btn").addClass("cursor-not-allowed")
      })
      $(".severity-text").addClass("disabled-text")
    }
  }

  cancelDisableHostIsolation(e){
    this.cancelFeatureToggle(this.hostIsolationConfirmationModalTarget, this.hostIsolationToggleTarget)
  }

  disableHostIsolation(e){
    this.ajaxToggleHostIsolation(false)
    $(this.hostIsolationConfirmationModalTarget).modal("hide")
    this.hostIsolationStatus = false
  }

  cancelDisableIdentityIsolation(e){
    this.cancelFeatureToggle(this.identityIsolationConfirmationModalTarget, this.identityIsolationToggleTarget)
  }

  disableIdentityIsolation(e){
    this.ajaxToggleIdentityIsolation(false)
    $(this.identityIsolationConfirmationModalTarget).modal("hide")
    this.identityIsolationStatus = false
  }

  cancelFeatureToggle(modalTarget, toggleTarget) {
    $(modalTarget).modal("hide")
    $(toggleTarget).parent(".toggle").trigger("click")
  }

  ajaxToggleHostIsolation(state){
    this.ajaxToggleFeature(
      state,
      "automated_host_isolation",
      "Successfully updated Huntress Managed Host Isolation settings",
      "Failed to update Huntress Managed Host Isolation settings"
    )
  }

  ajaxToggleIdentityIsolation(state) {
    this.ajaxToggleFeature(
      state,
      "automated_m365_identity_isolation",
      "Successfully updated Huntress Managed Identity Isolation settings",
      "Failed to update Huntress Managed Identity Isolation settings"
    )
  }

  ajaxToggleAutoRemediations() {
    this.ajaxToggleFeature(
      this.autoRemediationsToggleTarget.checked,
      "auto_remediations",
      "Successfully updated Huntress Active Remediations settings",
      "Failed to update Huntress Active Remediations settings"
    )
  }

  ajaxTogglecredentialReports() {
    this.ajaxToggleFeature(
      this.credentialReportsToggleTarget.checked,
      "credential_reports",
      "Successfully updated Credential Report settings",
      "Failed to update Credential Report settings"
    )
  }

  ajaxToggleHostIsolationExceptions() {
    this.ajaxToggleFeature(
      this.hostIsolationExceptionsToggleTarget.checked,
      "host_isolation_exceptions",
      "Successfully updated Isolation IP Address Allowlist settings",
      "Failed to update Isolation IP Address Allowlist settings"
    )
  }

  ajaxToggleFeature(state, feature_name, success_message, error_message){
    let account = {}
    account[this.data.get("accountId")] =  {
      [feature_name]: state ? "1": "0"
    }
    $.ajax({
      url: Routes.account_path({format: "js"}),
      method: "PATCH",
      contentType: "application/json",
      data: JSON.stringify({
        "features": {
          "account": account
        }
      }),
      success: (data, textStatus, jqXHR)=> {
        flashMessage(success_message, "success", this.element, false)
        if(feature_name != "auto_remediations"){
          this.scrollToTop()
        }
      },
      error: (jqXHR, textStatus, error) => {
        flashMessage(error_message, "warning", this.element, false)
        this.scrollToTop()
      }
    })
  }

  ajaxToggleAutoRemediationsSeverity(severity, state){
    $.ajax({
      url: Routes.toggle_auto_remediations_severity_account_path(),
      method: "PATCH",
      contentType: "application/json",
      data: JSON.stringify({
        "severity": severity,
        "state": state
      }),
      success: (data, textStatus, jqXHR)=> {
        if(this.severityCallbacks <= 0)
          flashMessage(`Successfully ${state ? "enabled" : "disabled"} ${severity} severity reports for Active Remediations`, "success", $("#managed-response-panel")[0], false)

        if(this.severityCallbacks > 0)
          this.severityCallbacks--
      },
      error: (jqXHR, textStatus, error) => {
        flashMessage(`Failed ${state ? "enabled" : "disabled"} ${severity} severity reports for Active Remediations `, "warning", $("#managed-response-panel")[0], false)
        if(this.severityCallbacks > 0)
          this.severityCallbacks--
      }
    })
  }

  scrollToTop(){
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
}
