import { Controller } from "@hotwired/stimulus"
import { formatCurrency } from "../../../helpers/moneyHelpers"

export default class extends Controller {
  static targets = [
    "expandButton",
    "saveButton",
    "minimum",
    "basePrice",
    "productSubtotal",
    "minimumWarning"
  ]

  static values = {
    product: String,
    prices: Array,
    subscription: { type: Object, default: {} },
    isAdd: Boolean,
    isUpgrade: Boolean,
    prevMinimum: Number
  }

  connect() {
    if (this.isUpgradeValue){
      this.prevMinimum = this.subscriptionValue.minimum
      this.setSelectedPlan(this.prevMinimum)
      this.onMinimumChanged()
    }
  }

  onProductToggled(e) {
    const { product } = e.detail

    this.element.hidden = product !== this.productValue
  }

  onProductRemoved(e) {
    const { product } = e.detail
    if (this.productValue !== product) return

    this.minimumTarget.value = ""
    this.basePriceTarget.value = ""
    this.productSubtotalTarget.value = ""
  }

  allowablePriceChange(minimum = 0) {
    return (this.isUpgradeValue && this.prevMinimum && minimum >= this.prevMinimum) || (this.isAddValue && minimum >= this.pricesValue[0].tier)
  }

  setSelectedPlan(minimum = 0){
    let new_price = null

    minimum = parseInt(minimum)

    if (this.allowablePriceChange(minimum)) {
      new_price = this.pricesValue.findLast(price => price.tier <= minimum)
    }

    this.selectedPlan = new_price
    this.dispatch("setSelectedPlan", { detail: { product: this.productValue, price: new_price, minimum: minimum }})
  }

  onMinimumChanged() {
    let minimum = this.minimumTarget.value
    this.setSelectedPlan(minimum)
    this.setFormState(minimum)
  }

  setFormState(minimum = 0) {
    let basePrice = ""
    let productSubtotal = ""

    minimum = (minimum === "" ? 0 : parseInt(minimum))

    if (minimum === 0){
      this.minimumWarningTarget.classList.add("hidden")
      this.minimumTarget.classList.remove("invalid")
    }

    if (minimum > 0) {
      if (this.isUpgradeValue && this.prevMinimum && this.prevMinimum > minimum) {
        this.minimumWarningTarget.textContent = `Minimum must be higher than ${this.prevMinimum}`
        this.minimumWarningTarget.classList.remove("hidden")
        this.minimumTarget.classList.add("invalid")
      }
      else if (minimum < this.pricesValue[0].tier) {
        this.minimumWarningTarget.textContent = `Must have a minimum of at least ${this.pricesValue[0].tier}`
        this.minimumWarningTarget.classList.remove("hidden")
        this.minimumTarget.classList.add("invalid")
      }
      else {
        this.minimumWarningTarget.classList.add("hidden")
        this.minimumTarget.classList.remove("invalid")

        let pricePer = this.selectedPlan.amount
        basePrice = formatCurrency(this.selectedPlan.currency, pricePer)
        productSubtotal = formatCurrency(this.selectedPlan.currency, pricePer * minimum)
      }
    }

    this.basePriceTarget.value = basePrice
    this.productSubtotalTarget.value = productSubtotal
  }

  keydown(event) {
    // Force the user to click the button to prevent accidental submission by pressing the 'enter' key.
    if (event.keyCode == 13){
      event.preventDefault()
      return false
    }
  }
}
