import { Controller } from "@hotwired/stimulus"
import { parseNestedFormData } from "huntressHelpers"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "scopeForm",
    "scopeSelection",
    "accountResponse",
    "organizationResponse",
    "awaitingScopeResponse",
    "titleCountry",
    "bodyCountry",
    "identityCount",
    "validationModal",
  ]

  connect() {
    $(this.scopeSelectionTarget).on("change", () => {
      this.scopeChange()
    })
  }

  scopeChange() {
    let scope = this.scopeSelectionTarget.value
    let showTarget
    switch(scope) {
    case "organization":
      showTarget = this.organizationResponseTarget
      break
    case "account":
      showTarget = this.accountResponseTarget
      break
    default:
      showTarget = this.awaitingScopeResponseTarget
    }

    [
      this.organizationResponseTarget,
      this.accountResponseTarget,
      this.awaitingScopeResponseTarget
    ].forEach(target => target.classList.add("hide"))
    showTarget.classList.remove("hide")
    this.scopeFormTarget.classList.remove("has-error")
  }

  awaitingScopeResolve() {
    this.scopeFormTarget.classList.add("has-error")
  }

  async confirmIfRequired() {
    let confirmUrl
    let formObject = this.parseFormData()

    switch(formObject.scope.value) {
    case "organization":
      confirmUrl = `${Routes.organization_unwanted_access_confirm_escalation_resolution_path(formObject.scope.organization_id)}?${new URLSearchParams(formObject.scope).toString()}`
      break
    case "account":
      // Delete the unused param at this scope
      delete formObject.scope.organization_id
      confirmUrl = `${Routes.account_unwanted_access_confirm_escalation_resolution_path()}?${new URLSearchParams(formObject.scope).toString()}`
      break
    }

    const response = await fetch(confirmUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const jsonResponse = await response.json()
    if(jsonResponse.identity_count) {
      return this.showIdentityCountModal(jsonResponse)
    }

    this.submitForm()
  }

  showIdentityCountModal(jsonResponse){
    this.titleCountryTarget.textContent = jsonResponse.country
    this.bodyCountryTarget.textContent = jsonResponse.country
    this.identityCountTarget.textContent = jsonResponse.identity_count

    $(this.validationModalTarget).modal("show")
  }

  parseFormData(){
    const formData = new FormData(this.scopeFormTarget)
    const formObject = parseNestedFormData(formData)
    return formObject
  }

  submitForm() {
    let formObject = this.parseFormData()
    const escalationId = formObject.scope.escalation_id
    let url

    switch(formObject.scope.value) {
    case "organization":
      url = Routes.resolve_organization_escalation_escalation_entities_path(formObject.scope.organization_id, escalationId, { resolution_action: "unauthorized" })
      break
    case "account":
      // Delete the unused param at this scope
      delete formObject.scope.organization_id
      url = Routes.resolve_account_escalation_escalation_entities_path(escalationId, { resolution_action: "unauthorized" })
      break
    }

    $.ajax(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/html",
      },
      complete: (xhr) => {
        $(this.validationModalTarget).modal("hide")
        window.location.reload()
      }
    })
  }
}
