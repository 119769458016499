// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

application.handleError = (error, message, detail) => {
  // Log the error and notify Bugsnag
  console.error("%s\n\n%o\n\n%o", message, error, detail)
  Bugsnag.notify(error)
}

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
