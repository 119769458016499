import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "category",
    "form",
    "ignoreAcknowledge",
    "input",
    "submitButton",
    "validate",
  ]

  connect() {
    this.toggleSubmitButton()
  }

  validateOnCategoryChange(event) {
    const category = event.currentTarget
    const input = category.nextElementSibling
    this.debouncedSubmit(input, category)
  }

  validateOnInputChange(event) {
    const input = event.currentTarget
    const category = input.previousElementSibling
    this.debouncedSubmit(input, category)
  }

  debouncedSubmit(input, category) {
    this.toggleSubmitButton()

    // This conditional check makes sure we only auto-submit exclusions for validation when we have both a category
    // and a input, otherwise validation doesn't make sense yet and creates many View<>Controller params edge cases.
    if (category.value.trim() === "" || input.value.trim() === "") {
      return
    }

    if(this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }

    this.debounceTimeout = setTimeout(() => this.submitForInputValidation(this), 750)
  }

  toggleSubmitButton() {
    let allValid = true

    this.categoryTargets.forEach((categoryTarget, index) => {
      const inputTarget = this.inputTargets[index]
      const categoryValue = categoryTarget.value.trim()
      const inputValue = inputTarget.value.trim()

      if ((categoryValue && !inputValue) || (!categoryValue && inputValue)) {
        allValid = false
      }
    })

    this.submitButtonTarget.disabled = !allValid

    if (this.hasIgnoreAcknowledgeTarget) {
      this.submitButtonTarget.disabled = !this.ignoreAcknowledgeTarget.checked
    }
  }

  remove(event) {
    event.currentTarget.parentElement.remove()
    this.submitForInputValidation()
  }

  disableInputs() {
    this.categoryTargets.forEach((element) => { element.disabled = true })
    this.inputTargets.forEach((element) => { element.disabled = true })
    this.submitButtonTarget.disabled = true
  }

  submitForInputValidation() {
    this.formTarget.requestSubmit(this.validateTarget)
    this.disableInputs()
  }
}
