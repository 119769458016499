import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {

  connect() {

    $("form.bulk_update").submit(function(e) {
      $(this).find("input[type=\"submit\"]")
        .val("Moving...")
        .attr("disabled", "disabled")

      $(this).find("a[data-dismiss]")
        .attr("disabled", "disabled")
        .click(function(e){ e.preventDefault(); return false })

      return true
    })

    $("form.bulk_uninstall").submit(function(e) {
      $(this).find("input[type=\"submit\"]")
        .val("Uninstalling...")
        .attr("disabled", "disabled")

      $(this).find("a[data-dismiss]")
        .attr("disabled", "disabled")
        .click(function(e){ e.preventDefault(); return false })

      return true
    })

    $("#datatable table")
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })
      .on("draw.dt", function() {
        $("#bulk-select-all").prop("checked", false)
      })

    let bulk_actions_enabled = $(".bulk-buttons").length != 0
    let filters = $("#datatable table").data("filters")

    $("#datatable table").dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
        { targets: [0], orderable: !bulk_actions_enabled },
        { targets: [-1], orderable: false },
      ],
      order: [[8, "desc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.organization_agents_path(this.data.get("currentOrganizationId"), {format: "json", filters: filters}),
    })
  }
}
