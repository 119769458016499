import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["loading", "datatable", "table"]

  connect() {
    $(this.tableTarget)
      .on("init.dt", this.tableInitialized.bind(this))
      .on("draw.dt", this.uncheckSelectAllBox.bind(this))
    this.setupDataTable()
  }

  tableInitialized() {
    $(this.loadingTarget).hide()
    $(this.datatableTarget).fadeIn()
  }

  uncheckSelectAllBox() {
    $("#bulk-select-all").prop("checked", false)
  }

  setupDataTable() {
    const bulk_actions_enabled = $(".bulk-buttons").length != 0
    const filters = $("#datatable table").data("filters")
    $(this.tableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
        { targets: [0], orderable: !bulk_actions_enabled },
      ],
      order: [[7, "desc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.account_agents_path({format: "json", filters: filters}),
    })
  }

  showMoveProgress(evt) {
    this.showFormProgress(evt.target, "Moving...")
  }

  showUninstallProgress(evt) {
    this.showFormProgress(evt.target, "Uninstalling...")
  }

  showFormProgress(formTarget, progressText) {
    $(formTarget).find("input[type=\"submit\"]")
      .val(progressText)
      .attr("disabled", "disabled")

    $(formTarget).find("a[data-dismiss]")
      .attr("disabled", "disabled")
      .click(function(e){
        e.preventDefault()
        return false
      })

    return true
  }
}
